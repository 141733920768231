import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild, DoCheck, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';
import { FileUploader, FileUploaderOptions, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import {
  Firestore,
  doc,
  updateDoc,
  docData,
  collection,
  collectionChanges,
  query,
  getDoc,
  getDocs,
  DocumentChange,
  DocumentData,
  SnapshotMetadata,
} from '@angular/fire/firestore';
import { v3 as uuidv3 } from 'uuid';
import { debounceTime, tap } from 'rxjs/operators';
import {
  AnimationStyleMetadata,
  style,
  AnimationAnimateMetadata,
  animate,
  keyframes,
  transition,
  trigger,
  state,
} from '@angular/animations';
import { MultiTabService } from '../services/multi-tab.service';
import { environment as environmentVars } from '../../environments/environment';
import { Session, TriggerStatus, TriggerData, EngageType, ChatEventType, WelcomeTriggerData } from '../model/Session';
import { CloudinaryResponse } from '../model/CloudinaryResponse';
import { ActorType, User } from '../model/user';
import {
  Message,
  MessageType,
  ButtonContent,
  TextPayloadMessageContent,
  ThreadStatus,
  WebviewMessageContent,
  AttachmentMessageContent,
  StreamedTextMessageContent,
} from '../model/message';
import { FeedbackPayload } from '../satisfaction/satisfaction-feedback/satisfaction-feedback.component';
import { SchemaSatisfactionPayload } from '../message/message.component';
import { BackendService } from '../services/backend.service';
import { DisplayService } from '../services/display.service';
import { RedirectService } from '../services/redirect.service';
import { ModalService } from '../modal';
import { SentMessageSanitizerService } from '../services/sent-message-sanitizer.service';
import * as utilities from '../misc/utilities';

const chatModes = ['chat', 'form'] as const;
export type ChatMode = (typeof chatModes)[number];

// eslint-disable  prefer-template
function get_cookie(name: string) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let c of ca) {
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

function delete_cookie(name: string, path: string, domain: string, sameSite: string, secure: boolean) {
  if (get_cookie(name)) {
    document.cookie = `${name}=${path ? `;path=${path}` : ''}${domain ? `;domain=${domain}` : ''}${
      sameSite ? `;samesite=${sameSite}` : ''
    }${secure ? ';secure' : ''};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
}
// eslint-enable  prefer-template

function eqJSON(obj1: any, obj2: any) {
  if (typeof obj1 !== typeof obj2) {
    return false;
  }
  if (typeof obj1 !== 'object') {
    return obj1 === obj2;
  }
  for (const i in obj2) {
    if (!obj1.hasOwnProperty(i) || !eqJSON(obj2[i], obj1[i])) {
      return false;
    }
  }
  return true;
}

function cloneJSON(obj: any) {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (err) {
    return undefined;
  }
}

const widgetAnimationDurationSeconds: number = 0.25;

/** Push up animation for messages */
const messageAnimationDurationSeconds: number = 0.35;
const messageStartingMargin: number = 10;

const pushUpStartStyle: AnimationStyleMetadata = style({
  opacity: 0,
  transform: `scaleY(0) scaleX(1) translateY(${messageStartingMargin}px)`,
  lineHeight: 0,
  overflow: 'hidden',
  height: '0',
  willChange: 'opacity, transform, line-height, overflow, height',
});
const pushUpEndStyle: AnimationStyleMetadata = style({
  opacity: 1,
  height: '*',
  transform: `scaleY(1) translateY(0)`,

  willChange: 'opacity, height, transform, line-height, overflow',

  offset: 1,
});
const pushUpAnimation: AnimationAnimateMetadata = animate(
  `${messageAnimationDurationSeconds}s ease-in-out`,
  keyframes([
    style({
      opacity: 0,
      transform: `scaleY(1) scaleX(1) translateY(${messageStartingMargin}px)`,
      lineHeight: 'initial',
      overflow: 'visible',

      willChange: 'opacity, height, transform, line-height, overflow',

      offset: 0,
    }),
    style({
      opacity: 0,
      height: `100%`,
      transform: `scaleY(1) scaleX(1) translateY(${messageStartingMargin}px)`,

      willChange: 'opacity, height, transform, line-height, overflow',

      offset: 0.25,
    }),
    style({
      opacity: 0.25,
      height: `100%`,
      transform: `scaleY(1) scaleX(1) translateY(${messageStartingMargin / 6}px)`,

      willChange: 'opacity, height, transform, line-height, overflow',

      offset: 0.5,
    }),
    pushUpEndStyle,
  ]),
);
const pushUpTransition: {
  startStyle: AnimationStyleMetadata;
  animation: AnimationAnimateMetadata;
  endStyle: AnimationStyleMetadata;
} = {
  startStyle: pushUpStartStyle,
  animation: pushUpAnimation,
  endStyle: pushUpEndStyle,
};

export type ChatAnimation = 'show' | 'hide';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  animations: [
    trigger('slideUpDownWidget', [
      state(
        'hide',
        style({
          opacity: 0,
          height: '*',
          transform: `translateY(1em)`,

          willChange: 'opacity, height, transform',
        }),
      ),
      state(
        'show',
        style({
          opacity: 1,
          height: '*',
          transform: `translateY(0)`,

          willChange: 'opacity, height, transform',
        }),
      ),
      transition('* => show', [
        animate(
          `${widgetAnimationDurationSeconds}s ease-in-out`,
          keyframes([
            style({
              opacity: 0,
              height: '*',
              transform: `translateY(1em)`,

              willChange: 'opacity, height, transform',

              offset: 0,
            }),
            style({
              opacity: 0,
              transform: `translateY(1em)`,

              willChange: 'opacity, height, transform',

              offset: 0.5,
            }),
            style({
              opacity: 1,
              height: '*',
              transform: `translateY(0)`,

              willChange: 'opacity, height, transform',

              offset: 1,
            }),
          ]),
        ),
      ]),
      transition('* => hide', [
        animate(
          `${widgetAnimationDurationSeconds}s ease-in-out`,
          keyframes([
            style({
              opacity: 1,
              height: '*',
              transform: `translateY(0)`,

              willChange: 'opacity, height, transform',

              offset: 0,
            }),
            style({
              opacity: 0,
              transform: `translateY(1em)`,

              willChange: 'opacity, height, transform',

              offset: 0.5,
            }),
            style({
              opacity: 0,
              transform: `translateY(1em)`,

              willChange: 'opacity, height, transform',

              offset: 1,
            }),
          ]),
        ),
      ]),
    ]),
    trigger('slideUpMessage', [transition('* => *', [pushUpTransition.animation])]),
    trigger('slideSendButton', [
      transition(':enter', [
        animate(
          `0.1s ease-in-out`,
          keyframes([
            style({
              opacity: 0,
              width: `0`,

              offset: 0,
            }),
            style({
              opacity: 0,
              width: `*`,
              transform: `translateX(10px)`,

              offset: 0.25,
            }),
            style({
              opacity: 1,
              transform: `translateX(0)`,

              offset: 1,
            }),
          ]),
        ),
      ]),
      transition(':leave', [
        animate(
          `0.1s ease-in-out`,
          keyframes([
            style({
              opacity: 1,
              transform: `translateX(0)`,

              offset: 0,
            }),
            style({
              opacity: 0,
              width: `*`,
              transform: `translateX(10px)`,

              offset: 0.25,
            }),
            style({
              opacity: 0,
              width: `0`,

              offset: 1,
            }),
          ]),
        ),
      ]),
    ]),
    /* Form mode */
    trigger('showHideTextarea', [
      transition(':enter', [
        animate(
          `0.25s ease-in-out`,
          keyframes([
            style({
              opacity: 0,
              height: `0`,

              offset: 0,
            }),
            style({
              opacity: 0,
              height: `*`,

              offset: 0.5,
            }),
            style({
              opacity: 1,
              height: `*`,

              offset: 1,
            }),
          ]),
        ),
      ]),
      transition(':leave', [
        animate(
          `0.1s ease-in-out`,
          keyframes([
            style({
              opacity: 1,
              height: `*`,

              offset: 0,
            }),
            style({
              opacity: 1,
              height: `0`,

              offset: 0.5,
            }),
            style({
              opacity: 0,
              height: `0`,

              offset: 1,
            }),
          ]),
        ),
      ]),
    ]),
    trigger('showHideBotMessages', [
      transition(':enter', [
        animate(
          `0.25s ease-in-out`,
          keyframes([
            style({
              opacity: 0,

              offset: 0,
            }),
            style({
              opacity: 1,

              offset: 1,
            }),
          ]),
        ),
      ]),
      transition(':leave', [
        animate(
          `0.1s ease-in-out`,
          keyframes([
            style({
              opacity: 1,

              offset: 0,
            }),
            style({
              opacity: 0,

              offset: 1,
            }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class WidgetComponent implements OnInit, DoCheck, OnDestroy {
  /* This is used for the chat's animation */
  @Input() chatAnimation: ChatAnimation;
  public isLoading: boolean = false;
  public enableDarkenAndBlurBackdrop: boolean = false;

  public satisfaction: number = null;
  public satisfactionComment: string = '';
  public hoverInd: number = -1;

  private subs: Subscription[] = [];

  broadcastChannel: BroadcastChannel = null;

  constructor(
    public displayService: DisplayService,
    private redirectService: RedirectService,
    private backendService: BackendService,
    private translate: TranslateService,
    private firestore: Firestore,
    private modalService: ModalService,
    private multiTabService: MultiTabService,
    private sentMessageSanitizerService: SentMessageSanitizerService,
  ) {
    this.user = null;
    this.handshakeServer();

    this.subs.push(
      this.displayService.chatStatus$
        .pipe(tap((status) => (this.chatAnimation = status === 'open' ? 'show' : 'hide')))
        .subscribe(),
    );
  }

  private cloudinaryConfig = {
    cloud_name: 'botmind',
    api_key: '816471574211148',
    upload_preset: 'r8wgs7f7',
  };

  public get expandHeader() {
    if (this.displayService.isStandalone) return false;
    return !this.messages.some((m) => !m.isWelcome);
  }
  public get selfCareContactFormWaitingReply() {
    if (!this.selfCareEnabled && this.chatMode !== 'form') {
      return false;
    }
    const message = this.shownMessages[this.shownMessages.length - 1];
    if (!message) {
      return false;
    }
    return (
      !message.author ||
      message.author.id === 'act_00000000-0000-0000-0000-000000000000' ||
      (this.user && this.user.id === message.author.id)
    );
  }
  public selfCareContactFormWaitingEngage = false;

  public shownMessages: Message[] = [];
  public ghostMessagesQueue: Message[] = [];
  public ghostSessionBackup: Session;
  public ghostSessionSelfCareAskQuestion = false;
  public hideTextarea: boolean = true;
  public hoverActionButton = 'none';
  public hideTextareaUntilButtonClick: boolean = null;

  public messagesClear() {
    this.messages = [];
    this.shownMessages = [];
  }

  public messagesAssign(messages: Message[]) {
    this.messages = messages;
    this.messagesSort();
    this.messagesFilter();
  }

  public messagesSort() {
    this.messages = this.messages.sort((a, b) => {
      return a.sentDate - b.sentDate;
    });
  }

  public messagesFilter() {
    const visibleMessages = this.messages.filter((m, i) => {
      return !this.hideMessage(i);
    });
    if (!this.selfCareEnabled && this.chatMode !== 'form') {
      this.shownMessages = visibleMessages;
      this.selfCareContactFormDisableText = false;
    } else {
      if (!this.selfCareEnabled) {
        this.shownMessages = visibleMessages;
      }
      let i = visibleMessages.length;
      let selfCareContactFormDisableText = false;
      const result: Message[] = [];
      while (i > 0) {
        i -= 1;
        const message = visibleMessages[i];
        if (
          // If Message is written by visitor
          !message.author ||
          message.author.id === 'act_00000000-0000-0000-0000-000000000000' ||
          (this.user && this.user.id === message.author.id)
        ) {
          if (i !== visibleMessages.length - 1) {
            break;
          }
        }
        result.unshift(visibleMessages[i]); // Push-front
        if ([MessageType.BUTTONS, MessageType.QUICK_REPLIES].includes(message.type)) {
          // If buttons
          selfCareContactFormDisableText = true; // Disable text field
        }
      }

      if (
        this.chatMode === 'form' &&
        !selfCareContactFormDisableText &&
        this.session &&
        this.session.threadStatus === ThreadStatus.CLOSED
      ) {
        selfCareContactFormDisableText = true;
      }

      this.selfCareContactFormDisableText = selfCareContactFormDisableText;

      if (this.selfCareEnabled) {
        this.shownMessages = result;
      }

      this.updateSelfCareContactFormTextareaStatus();
    }
  }

  public messagesPush(message: Message, makeMessageTheLast: boolean) {
    if (makeMessageTheLast) {
      let time = 0;
      for (const m of this.messages) {
        if (m.sentDate > time) {
          time = m.sentDate;
        }
      }
      message.sentDate = time + 1;
    }
    this.messages.push(message);
    this.messagesSort();
    this.messagesFilter();

    this.autoOpenWebview();
  }

  public messagesSplice(messageInd: number) {
    this.messages.splice(messageInd, 1);
    this.messagesSort();
    this.messagesFilter();
  }

  public onCloseWebview(): void {
    this.webviewUrl = null;
  }

  public showWebview(webviewMessage: WebviewMessageContent): void {
    this.webviewUrl = webviewMessage;
  }

  public session: Session;

  @ViewChild(NgScrollbar, { static: false })
  perfectScrollbar?: NgScrollbar;
  @ViewChild('newMessageInput', { static: false }) newMessageInput: ElementRef<HTMLTextAreaElement>;
  @ViewChild('messagesContainer', { static: false }) messagesContainer: ElementRef<HTMLDivElement>;

  public user: User | null;

  public newMessage: string;

  public messages: Message[] = [];

  private ioConnection: Subscription;

  private shouldRedirect: boolean;

  public chatHidden = true;

  public selfCareEnabled = false;

  public chatMode: ChatMode = 'chat';

  public selfCareContactFormDisableText = true;

  public ghostMode = true;

  public previousChatStatus: 'open' | 'closed' = 'closed';

  public focusMessageIndex = -1;

  public pendingFiles: File[] = [];

  private uploader: FileUploader;

  public host: string;

  private engageTimerHandle = -1;

  private welcomeTimerHandle = -1;

  private activeEngageIdentifier: string = '';

  public promptingSatisfaction: boolean = false;

  public language: string | null = null;

  public selfCareAskButtonText: string = '';

  public visitorIsTyping: boolean = false;

  public disableScrollDownOnOpen = false;
  public referralLink = new URL(
    'https://www.botmind.io/?utm_source=Widget&utm_medium=LogoReferral&utm_campaign=UnknowAccount',
  );

  public parentPageUrl: string;
  public parentPageTitle: string;
  public handshakeTime: number;

  public webviewUrl: string | null = null;

  private sessionSubscription: null | Subscription;
  private messagesSubscription: null | Subscription;

  private async engage(trigger: TriggerData | WelcomeTriggerData, isWelcome: boolean) {
    this.debugLog('engage request', trigger);

    if (!trigger) {
      return;
    }

    if (trigger.isPopTriggerOnly) {
      if (this.displayService.chatStatus === 'closed' && !this.session.dismissedPopUp) {
        this.displayService.popTriggerService.clearMessages();
        trigger.messages.forEach((message) => {
          void this.displayService.popTriggerService.addMessage(message);
        });
        if (!this.ghostMode) {
          void this.backendService.notifyEngage(
            EngageType.CATCH,
            this.parentPageUrl,
            this.parentPageTitle,
            trigger.triggerId,
            isWelcome ? (trigger as WelcomeTriggerData).welcomeMessageId : null,
          );
        }
      }
      return;
    }

    // If triggerStatus is not 'waiting'
    if (
      ![
        TriggerStatus.WAITING,
        TriggerStatus.WELCOMING,
        TriggerStatus.ENGAGING,
        TriggerStatus.OVERRIDDEN,
        TriggerStatus.WELCOMING_ENGAGING,
      ].includes(this.session.triggerStatus) ||
      this.activeEngageIdentifier === trigger.identifier
    ) {
      // return
      return;
    }
    // If there is a thread
    if (this.session.threadId !== 'thr_00000000-0000-0000-0000-000000000000' && !trigger.overridingActiveThread) {
      // return
      return;
    }
    // If chat is not available
    if (!this.session.enabled || !this.session.mobileEnabled) {
      return;
    }

    if (isWelcome) {
      this.welcomeTimerHandle = -1;
      if (![TriggerStatus.WELCOMING, TriggerStatus.WELCOMING_ENGAGING].includes(this.session.triggerStatus)) {
        return;
      }
    } else {
      this.engageTimerHandle = -1;
      // Set triggerStatus from 'waiting' to 'engaging'
      if (this.session.triggerStatus !== TriggerStatus.WELCOMING) {
        if (!this.ghostMode) {
          await this.backendService.updateTriggerStatus(TriggerStatus.ENGAGING);
        } else {
          this.session.triggerStatus = TriggerStatus.ENGAGING;
        }
      }
    }
    if (this.messages.length) {
      this.messagesClear();
      this.displayService.newMessages = 0;
    }
    this.debugLog('Engage');
    this.selfCareContactFormWaitingEngage = true;
    this.activeEngageIdentifier = trigger.identifier;

    // If trigger is set to pop-up, open chat
    if (trigger.popUp) {
      this.displayService.openChat(false, this);
    }

    // Push messages
    trigger.messages.forEach(async (socketMessage) => {
      this.pushMessage(socketMessage, false, isWelcome);
      await this.sleep(100);
    });
    let type: EngageType;
    let triggerId: string | undefined;
    if (isWelcome) {
      type = EngageType.WELCOME;
    } else if (trigger.overridingActiveThread) {
      type = EngageType.LIVE;
    } else {
      type = EngageType.NORMAL;
      triggerId = trigger.triggerId;
    }
    if (!this.ghostMode) {
      void this.backendService.notifyEngage(
        type,
        this.parentPageUrl,
        this.parentPageTitle,
        triggerId,
        isWelcome ? (trigger as WelcomeTriggerData).welcomeMessageId : null,
      );
    }
    const receivedMessages = this.ghostMode ? trigger.messages : await this.backendService.sendEngageMessage(trigger);
    if (!this.displayService.isStandalone) this.displayService.popTriggerService.clearMessages();
    receivedMessages.forEach((message) => {
      this.displayService.onNewMessage(message, this.session.dismissedPopUp);
      this.debugLog('Message got received', message);
    });
    this.selfCareContactFormWaitingEngage = false;
  }

  public clearWelcome() {
    this.debugLog('Clear welcome');
    if (this.welcomeTimerHandle !== -1) {
      this.debugLog('Clearing handle', this.welcomeTimerHandle);
      window.clearTimeout(this.welcomeTimerHandle);
      this.welcomeTimerHandle = -1;
    }
  }

  public clearEngage() {
    this.debugLog('Clear engage');
    if (this.engageTimerHandle !== -1) {
      this.debugLog('Clearing handle', this.engageTimerHandle);
      window.clearTimeout(this.engageTimerHandle);
      this.engageTimerHandle = -1;
    }
  }

  private async handshakeServer() {
    const url = new URL(document.URL);
    let host: string;
    let sessionId: string;
    let accountId: string;
    let resetSession: boolean;
    let pathname: string;
    let pageUrl: string;
    let pageTitle: string;
    let language: string;
    let visitorEmail = '';
    let visitorFirstName = '';
    let visitorLastName: string;
    let rawCustomData: string;
    let clearTpCookies: boolean;
    let isMobile: boolean;
    let startWithWidgetOpen: boolean;
    let freeDivMode: boolean;
    let contactFormMode: boolean;
    let consentReceived: boolean;
    let urlDefinedSid: boolean;
    let storeIp: string | null;
    let prefixedSession: string | null;
    if (window.parent.window === window) {
      this.displayService.isStandalone = true;
      this.displayService.setChatStatus('open', false, false, this);
      host = environmentVars.backendUrl;
      const accountToken = url.searchParams.get('accountToken') || '';
      ({ sessionId, accountId, resetSession, storeIp } = await this.backendService.getUids(accountToken));
      const recordedSessionId = this.standaloneGetSessionId();
      if (recordedSessionId) {
        sessionId = recordedSessionId;
        resetSession = false;
        this.backendService.sessionId = recordedSessionId;
      }
      pathname = 'no-iframe';
      pageUrl = window.location.href;
      pageTitle = 'Standalone Widget';
      language = url.searchParams.get('language') || 'fr';
      visitorEmail = url.searchParams.get('visitorEmail') || '';
      visitorFirstName = url.searchParams.get('visitorFirstName') || '';
      visitorLastName = url.searchParams.get('visitorLastName') || '';
      rawCustomData = url.searchParams.get('customData') || '[]';
      clearTpCookies = false;
      isMobile = true;
      startWithWidgetOpen = true;
      freeDivMode = false;
      contactFormMode = false;
      consentReceived = true;
      urlDefinedSid = false;
      storeIp = null;
      prefixedSession = null;
    } else {
      sessionId = url.searchParams.get('uid');
      host = url.searchParams.get('host');
      accountId = url.searchParams.get('aid');
      pathname = url.searchParams.get('pathName');
      pageUrl = url.searchParams.get('pageUrl');
      pageTitle = url.searchParams.get('pageTitle');
      language = url.searchParams.get('language');
      visitorEmail = url.searchParams.get('visitorEmail') || '';
      visitorFirstName = url.searchParams.get('visitorFirstName') || '';
      visitorLastName = url.searchParams.get('visitorLastName') || '';
      rawCustomData = url.searchParams.get('customData') || '[]';
      clearTpCookies = (url.searchParams.get('clearTpCookies') || 'false') === 'true';
      isMobile = (url.searchParams.get('isMobile') || 'false') === 'true';
      resetSession = (url.searchParams.get('reset') || 'false') === 'true';
      startWithWidgetOpen = (url.searchParams.get('startWithWidgetOpen') || 'false') === 'true';
      freeDivMode = (url.searchParams.get('freeDivMode') || 'false') === 'true';
      contactFormMode = (url.searchParams.get('contactFormMode') || 'false') === 'true';
      consentReceived = (url.searchParams.get('consentReceived') || 'false') === 'true';
      urlDefinedSid = (url.searchParams.get('urlDefinedSid') || 'false') !== 'false';
      storeIp = url.searchParams.get('sip') || null;
      prefixedSession = url.searchParams.get('prefixedSession') || null;
      if (prefixedSession === 'null') prefixedSession = null;
    }
    const { userAgent } = window.navigator;

    if (freeDivMode) {
      startWithWidgetOpen = true;
    }
    if (contactFormMode) {
      this.chatMode = 'form';
      this.hideTextarea = true;
    } else {
      this.hideTextarea = false;
    }

    this.ghostMode = !consentReceived;
    this.parentPageUrl = pageUrl;
    this.parentPageTitle = pageTitle;

    this.displayService.widgetOnRight = (url.searchParams.get('widgetOnRight') || 'true') === 'true';
    this.displayService.fullHeight = (url.searchParams.get('fullHeight') || 'false') === 'true';
    this.displayService.freeDivMode = freeDivMode;
    if (['en', 'fr', 'es', 'de', 'it', 'nl'].includes(language)) {
      this.translate.use(language);
    }
    if (language) {
      this.language = language;
    }

    this.multiTabService.init(sessionId);

    this.host = host;

    const addUrlParamsToCustomData: boolean = true;

    if (addUrlParamsToCustomData) {
      rawCustomData = JSON.stringify(this.addUrlParamsToCustomData(pageUrl, rawCustomData));
    }

    const {
      clearThirdPartyCookies,
      firebaseSessionId,
      referralRule,
      environment,
      now,
      initSession,
      initMessages,
      accountName,
    } = await this.backendService.handshake(
      host,
      sessionId,
      accountId,
      pathname,
      pageUrl,
      pageTitle,
      language,
      visitorEmail,
      visitorFirstName,
      visitorLastName,
      rawCustomData,
      startWithWidgetOpen,
      resetSession,
      userAgent,
      isMobile,
      this.ghostMode,
      storeIp,
      urlDefinedSid,
      prefixedSession,
    );
    if (initSession?.welcomeTriggerData?.hideTextInput && initSession.triggerStatus !== TriggerStatus.ENGAGED) {
      this.hideTextareaUntilButtonClick = true;
    } else {
      this.hideTextareaUntilButtonClick = false;
    }

    this.displayService.removeReferral = referralRule === 'hide';
    this.displayService.referralIsLink = referralRule === 'normal';

    this.displayService.setEnvironment(environment);
    this.referralLink.searchParams.set('utm_campaign', encodeURIComponent(accountName));
    this.handshakeTime = now;

    /* initMessages.forEach((m) => {
      m.sentDate = fixDate(m.sentDate);
      m.updatedDate = fixDate(m.updatedDate);
    }); */

    this.setSession(initSession, true);
    if (initMessages.length > this.messages.length) {
      this.messagesAssign(initMessages);
    }

    await this.subscribeToSessionChange(firebaseSessionId);

    if (this.displayService.isStandalone) {
      this.standaloneRegisterSessionId();
      this.attachInternalApi();
    }
    this.hideTextarea = this.hideTextareaUntilButtonClick ?? false;
  }

  private standaloneRegisterSessionId() {
    window.localStorage.setItem('bc-standalone-session-id', this.session.id);
  }

  private standaloneGetSessionId() {
    return window.localStorage.getItem('bc-standalone-session-id');
  }

  private attachInternalApi() {
    (window as any).botmindChatInternalApi = {
      getCurrentSessionId: () => {
        return this.session.id;
      },
    };
  }

  private handleNewSessionUpdate(session: Session) {
    if (session) {
      this.setSession(session);
      if (
        this.hideTextareaUntilButtonClick === null &&
        session?.welcomeTriggerData?.hideTextInput &&
        session.triggerStatus !== TriggerStatus.ENGAGED
      ) {
        this.hideTextareaUntilButtonClick = true;
      }
    }
  }

  private handleMessagesUpdate(changes: DocumentChange<DocumentData, DocumentData>[]) {
    this.debugLog('MESSAGES changed');
    changes.forEach((change, i) => {
      const message = change.doc.data() as Message;
      if (
        message.type === MessageType.CUSTOMER_SCRIPT &&
        message.author.type === ActorType.BOT &&
        (message.content as string).includes(`/triggerScript/`)
      ) {
        const triggerScript = (message.content as string).split(`/`);
        const scriptName = triggerScript[2];
        const scriptArgs = triggerScript[3];
        const scriptAfterText = triggerScript[4];

        if (this.multiTabService.getIsActiveTab()) {
          parent.window.postMessage({ type: scriptName, data: scriptArgs }, '*');
          sessionStorage.setItem(`scriptExecuted-${scriptName}`, scriptArgs);
        }

        message.type = MessageType.TEXT;
        message.content = scriptAfterText;
      }

      const isLastMessage = changes.length - 1 === i;

      // This conditions allows the textarea to be hidden when the option is activated in the welcome message.
      // It must be validated only if the button message was the last one in the conversation

      if (
        change.type !== 'removed' &&
        message.type === MessageType.BUTTONS &&
        message?.hideTextInput !== null &&
        message?.hideTextInput !== undefined &&
        message?.hideTextInput &&
        isLastMessage
      ) {
        this.hideTextareaUntilButtonClick = true;
      }

      /* message.sentDate = fixDate(message.sentDate);
        message.updatedDate = fixDate(message.updatedDate); */
      this.debugLog(change.type);
      this.debugLog(message);
      if (change.type === 'removed') {
        this.removeMessage(message);
      } else if (change.type === 'added' || change.type === 'modified') {
        this.pushMessage(message, message.updatedDate < this.handshakeTime, false);
      }
    });
  }

  public async subscribeToSessionChange(firebaseSessionId: string, force = false) {
    if (!this.broadcastChannel) {
      this.broadcastChannel = new BroadcastChannel(`botmind-chat-${firebaseSessionId}`);
      this.broadcastChannel.onmessage = (event) => {
        if (event.data === 'startRealtime') {
          void this.subscribeToSessionChange(firebaseSessionId, true);
        }
      };
    }
    if (this.ghostMode) {
      return;
    }

    const sessionRef = doc(this.firestore, `${this.displayService.environment}-sessions-collection`, firebaseSessionId);
    if (!this.sessionSubscription) {
      const sessionSnap = (await getDoc(sessionRef)).data() as Session;
      if (sessionSnap.noUserMessageSent && !force) {
        this.handleNewSessionUpdate(sessionSnap);

        const messagesSnapshot = await getDocs(
          collection(
            this.firestore,
            `${this.displayService.environment}-sessions-collection/${firebaseSessionId}/messages`,
          ),
        );

        const fakeMessagesChanges: DocumentChange<DocumentData, DocumentData>[] = messagesSnapshot.docs.map(
          (document) => ({
            type: 'added',
            doc: {
              id: document.id,
              data: () => document.data(),
              metadata: {} as SnapshotMetadata,
              exists: () => Promise.resolve(true),
              ref: document.ref,
              get: () => Promise.resolve(document.data()),
            },
            oldIndex: -1,
            newIndex: 0,
          }),
        );
        this.handleMessagesUpdate(fakeMessagesChanges);
      } else {
        this.broadcastChannel.postMessage('startRealtime');
        this.sessionSubscription = docData(sessionRef).subscribe((session: Session) => {
          this.handleNewSessionUpdate(session);
        });
        if (!this.messagesSubscription) {
          const col = collection(
            this.firestore,
            `${this.displayService.environment}-sessions-collection/${firebaseSessionId}/messages`,
          );
          const qr = query(col);
          this.messagesSubscription = collectionChanges(qr).subscribe((changes) => {
            this.handleMessagesUpdate(changes);
          });
        }
      }
    }
  }

  public setSession(session: Session, isFirstSet = false) {
    this.debugLog('Session changed');

    const completeCloudinaryUrl: string | undefined = session.chatStyle.welcomeHeaderStyle['background-image']?.slice(
      5,
      -2,
    );
    if (completeCloudinaryUrl && completeCloudinaryUrl.length) {
      const optimisedCloudinaryUrl = completeCloudinaryUrl.replace(
        'botmind/image/upload/',
        'botmind/image/upload/c_limit,w_800,f_auto/',
      );
      session.chatStyle.welcomeHeaderStyle['background-image'] = `url("${optimisedCloudinaryUrl}")`;
    }

    this.displayService.chatStyle = session.chatStyle;

    this.checkSessionDiff(this.session, session);

    if (this.displayService.freeDivMode) {
      this.displayService.setChatStatus('open', false, !this.session, this);
    } else if (!isFirstSet) {
      this.displayService.setChatStatus(session.open ? 'open' : 'closed', false, !this.session, this);
    } else if (!this.displayService.isMobileDisplay) {
      this.displayService.setChatStatus(session.open ? 'open' : 'closed', false, !this.session, this);
    } else if (!this.displayService.isStandalone) this.displayService.closeChat(false, true);

    this.displayService.refreshIframeStyle();

    if (session.customer) {
      this.user = new User(
        session.customer.email,
        session.customer.firstName,
        session.customer.lastName,
        session.customer.type,
        session.customer.id,
        session.customer.avatar,
        session.customer.customData,
      );
    }

    for (const m of this.messages) {
      if (m.author === null || m.author.id === this.user.id) {
        m.author = this.user;
      }
    }

    const oldSessionTriggerData = this.session ? this.session.triggerData : null;
    const oldSessionWelcomeTriggerData = this.session ? this.session.welcomeTriggerData : null;

    this.session = session;

    if (
      [
        TriggerStatus.ENGAGING,
        TriggerStatus.WAITING,
        TriggerStatus.OVERRIDDEN,
        TriggerStatus.WELCOMING_ENGAGING,
      ].includes(this.session.triggerStatus) &&
      this.session.triggerData
    ) {
      this.session.triggerData.messages.forEach((m) => {
        /* m.sentDate = fixDate(m.sentDate);
        m.updatedDate = fixDate(m.updatedDate); */
      });
      if (
        !oldSessionTriggerData ||
        !eqJSON(JSON.parse(JSON.stringify(oldSessionTriggerData)), JSON.parse(JSON.stringify(this.session.triggerData)))
      ) {
        if (this.session.triggerData.delay <= 0) {
          this.engage(this.session.triggerData, false);
        } else {
          this.clearEngage();
          this.engageTimerHandle = window.setTimeout(() => {
            this.engage.bind(this)(this.session.triggerData, false);
          }, this.session.triggerData.delay);
        }
      }
    }

    if (
      [TriggerStatus.WELCOMING, TriggerStatus.WELCOMING_ENGAGING].includes(this.session.triggerStatus) &&
      this.session.welcomeTriggerData
    ) {
      this.session.welcomeTriggerData.messages.forEach((m) => {
        /* m.sentDate = fixDate(m.sentDate);
        m.updatedDate = fixDate(m.updatedDate); */
      });
      if (
        !oldSessionWelcomeTriggerData ||
        !eqJSON(
          JSON.parse(JSON.stringify(oldSessionWelcomeTriggerData)),
          JSON.parse(JSON.stringify(this.session.welcomeTriggerData)),
        )
      ) {
        if (this.session.welcomeTriggerData.delay <= 0) {
          this.engage(this.session.welcomeTriggerData, true);
        } else {
          this.clearWelcome();
          this.welcomeTimerHandle = window.setTimeout(() => {
            this.engage.bind(this)(this.session.welcomeTriggerData, true);
          }, this.session.welcomeTriggerData.delay);
        }
      }
    }

    if (this.redirectService) {
      this.redirectService.setUserCall(this.user);
    }

    const askButtonTextItem =
      session.selfCareAskButtonText.find((t) => t.language === this.language) || session.selfCareAskButtonText[0];
    if (askButtonTextItem) {
      this.selfCareAskButtonText = askButtonTextItem.content;
    }
  }

  private debugLog(...args: any[]) {
    // if (isDevMode()) {
    // console.log('[DEBUG]', ...args);
    // }
  }

  private sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  ngOnInit() {
    this.newMessage = '';
    this.messagesClear();
    // Create the file uploader, wire it to upload to your account
    const uploaderOptions: FileUploaderOptions = {
      url: `https://api.cloudinary.com/v1_1/${this.cloudinaryConfig.cloud_name}/upload`,
      // Upload files automatically upon addition to upload queue
      autoUpload: false,
      // Use xhrTransport in favor of iframeTransport
      isHTML5: true,
      // Calculate progress independently for each uploaded file
      removeAfterUpload: true,
      // XHR request headers
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest',
        },
      ],
    };

    this.uploader = new FileUploader(uploaderOptions);

    this.uploader.onErrorItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders,
    ): any => {
      this.debugLog('FILE ERROR');
      this.debugLog(response);
      this.debugLog(status);
      this.debugLog(headers);
    };

    this.uploader.onBuildItemForm = (fileItem: FileItem, form: FormData) => {
      return { fileItem, form };
    };

    this.displayService.openCloseSubject.subscribe((status: 'open' | 'closed') => {
      this.clearEngage();
      this.clearWelcome();
    });

    this.displayService.hrefChangedSubject.subscribe((data: { href: string; pageName: string }) => {
      this.redirectService.hrefChanged(data.href, data.pageName);
      this.backendService
        .hrefChanged(data.href, data.pageName, this.ghostMode, this.session.accountId, this.session)
        .then((newSession) => {
          if (this.ghostMode) {
            this.setSession(newSession, false);
          }
          this.parentPageUrl = data.href;
          this.parentPageTitle = data.pageName;
        })
        .catch(console.error);
    });

    this.displayService.setUserSubject.subscribe((user) => {
      this.user = user;
    });

    if (this.newMessageInput) {
      fromEvent(this.newMessageInput.nativeElement, 'input')
        .pipe(
          tap((ev) => {
            this.visitorIsTyping = true;
            if (this.shouldRedirect) {
              this.redirectService.visitorTyping(this.visitorIsTyping);
            }
            // TODO: update session
            return ev;
          }),
          debounceTime(2500),
          tap((ev) => {
            this.visitorIsTyping = false;
            if (this.shouldRedirect) {
              this.redirectService.visitorTyping(this.visitorIsTyping);
            }
            // TODO: update session
            return ev;
          }),
        )
        .subscribe();
    }
  }

  async ngDoCheck() {
    if (this.displayService.chatStatus !== this.previousChatStatus) {
      this.previousChatStatus = this.displayService.chatStatus;
      const elem: HTMLElement = this.perfectScrollbar?.nativeElement;
      if (this.displayService.chatStatus === 'open') {
        while (elem?.scrollHeight === 0 && elem?.scrollHeight > elem?.clientHeight) {
          await this.sleep(0);
        }
        if (!this.disableScrollDownOnOpen) {
          this.scrollDown();
          setTimeout(() => {
            this.scrollDown();
          }, 200);
        } else {
          this.disableScrollDownOnOpen = false;
        }

        this.chatHidden = false;

        this.newMessage = this.newMessage || '';

        if (!this.displayService.isMobileDisplay) {
          // Set focus
          await this.sleep(0);
          this.newMessageInput?.nativeElement.focus();
        }
      } else {
        this.chatHidden = true;
      }
    }
  }

  private checkSessionDiff(oldSession: null | Session, newSession: Session) {
    if (!oldSession || oldSession.thirdPartyTakeover !== newSession.thirdPartyTakeover) {
      if (!oldSession && newSession.thirdPartyTakeover) {
        parent.window.postMessage({ type: 'bc_resumeTpTakeover' }, '*');
      } else if (newSession.thirdPartyTakeover) {
        const history = this.getHistory();
        window.setTimeout(() => {
          parent.window.postMessage(
            {
              type: 'bc_tpTakeover',
              data: {
                transcript: history,
                messages: this.messages,
                visitorMessages: this.messages.filter((m) => m.author.id === this.user.id),
                visitor: this.user,
              },
            },
            '*',
          );
        }, 2000);
      } else {
        parent.window.postMessage({ type: 'bc_endTpTakeover' }, '*');
      }
    }

    if (
      (!oldSession || !oldSession.enabled || !oldSession.mobileEnabled) &&
      (newSession.enabled || newSession.mobileEnabled)
    ) {
      this.displayService.enable(
        newSession,
        newSession.open ? 'open' : 'closed',
        this,
        newSession.chatStyle.launcherButtonStyle,
      );
    }
    if (
      (!oldSession || oldSession.enabled || oldSession.mobileEnabled) &&
      (!newSession.enabled || !newSession.mobileEnabled)
    ) {
      this.displayService.disable();
    }

    if (
      (!oldSession || !eqJSON(oldSession.popOnlyMessages, newSession.popOnlyMessages)) &&
      newSession.popOnlyMessages.length
    ) {
      if (this.displayService.chatStatus === 'closed') {
        if (!this.displayService.isStandalone) {
          this.displayService.popTriggerService.clearMessages();
          newSession.popOnlyMessages.forEach((message) => {
            this.displayService.popTriggerService.addMessage(message);
          });
        }
      }
      if (!this.ghostMode) {
        const ref = doc(this.firestore, `${this.displayService.environment}-sessions-collection/${newSession.id}`);
        updateDoc(ref, { popOnlyMessages: [] });
      } else {
        newSession.popOnlyMessages = [];
        oldSession.popOnlyMessages = [];
      }
    }

    if (!oldSession || oldSession.redirectionEnabled !== newSession.redirectionEnabled) {
      this.debugLog('Redirection status changed', newSession.redirectionEnabled);
      if (oldSession && !newSession.redirectionEnabled) {
        this.redirectService.endRedirection();
      }
      this.shouldRedirect = newSession.redirectionEnabled;
      if (oldSession && this.shouldRedirect) {
        // If the order to redirect is new
        this.debugLog('Redirection was not enabled: sending history');
        if (
          newSession.accountId === 'acc_c2d60302-1d40-5c59-8721-31a73315e6bc' ||
          newSession.accountId === 'acc_fadc498e-8f5e-5612-987f-2bbb1fc2c56e'
        ) {
          let ipAdress = newSession.customer.customData.find((dataItem) => {
            return dataItem.key === 'visitorIP';
          });
          if (!ipAdress?.value) {
            ipAdress = { key: 'visitorIP', value: '' };
          }
          this.redirectService.sendHistoryCall(this.messages, ipAdress.value);
        } else {
          this.redirectService.sendHistoryCall(this.messages);
        }
      }
      if (oldSession && !this.shouldRedirect) {
        this.debugLog('Redirection was disabled');
      }
    }

    if (!oldSession || oldSession.selfCareEnabled !== newSession.selfCareEnabled) {
      this.updateSelfCareMode(newSession.selfCareEnabled);
    }

    if (newSession.rhInit && (!oldSession || !eqJSON(oldSession.rhInit, newSession.rhInit))) {
      this.debugLog('EVENT-SET_REDIRECT');
      this.redirectService.init(newSession.rhInit, this);
    }

    if (!oldSession || JSON.stringify(oldSession.rhData) !== JSON.stringify(newSession.rhData)) {
      this.redirectService.onRhDataChangedCall(newSession.rhData);
    }

    if (!oldSession || oldSession.promptSatisfaction !== newSession.promptSatisfaction) {
      if (newSession.promptSatisfaction) {
        window.setTimeout(() => {
          this.promptingSatisfaction = true;
        }, 5000);
      } else if (!newSession.promptSatisfaction) {
        this.promptingSatisfaction = false;
      }
    }
    if (newSession.agentIsTyping && (!oldSession || !oldSession.agentIsTyping)) {
      this.scrollDown();
    }

    if (!oldSession || oldSession.threadStatus !== newSession.threadStatus) {
      if (
        newSession.threadStatus === ThreadStatus.CLOSED &&
        this.chatMode === 'form' &&
        !this.selfCareContactFormDisableText
      ) {
        this.selfCareContactFormDisableText = true;
      }

      this.updateSelfCareContactFormTextareaStatus();
    }
  }

  private async updateSelfCareMode(enabled: boolean) {
    this.selfCareEnabled = enabled;
    this.messagesSort();
    this.messagesFilter();

    if (!this.session?.welcomeTriggerData || this.shownMessages?.length < 1) {
      this.hideTextarea = false;
    } else {
      this.hideTextarea = enabled;
    }
    if (!enabled) {
      await this.sleep(0);
      this.newMessageInput?.nativeElement.focus();
    }
  }

  public checkFiles(files: DataTransferItem[]): boolean {
    for (const file of files) {
      if (
        file.type !== 'application/pdf' &&
        file.type.slice(0, 6) !== 'image/' &&
        file.type.slice(0, 9) !== 'video/mp4'
      ) {
        return false;
      }
    }
    return true;
  }

  public onDrop(files: File[]) {
    for (const file of files) {
      const isMP4 = file.type.slice(0, 9) === 'video/mp4';
      if (file.type !== 'application/pdf' && file.type.slice(0, 6) !== 'image/' && !isMP4) {
        window.alert(`Invalid file type: ${file.type || file.name.split('.').slice(-1)[0]}`);
        continue;
      }
      if (file.size > 10 * 1024 * 1024 && !isMP4) {
        window.alert('File size must be inferior to 10MB');
        continue;
      } else if (file.size > 25 * 1024 * 1024 && isMP4) {
        window.alert('Video file size must be inferior to 25MB');
        continue;
      }
      this.isLoading = true;
      this.pendingFiles.push(file);
    }
    this.sendPendingFiles();
  }

  public fileInput(e: Event) {
    const input = e.target as HTMLInputElement;
    for (const file of Array.from(input.files)) {
      const isMP4 = file.type.slice(0, 9) === 'video/mp4';
      if (file.type !== 'application/pdf' && file.type.slice(0, 6) !== 'image/' && !isMP4) {
        window.alert(`Invalid file type: ${file.type || file.name.split('.').slice(-1)[0]}`);
        continue;
      }
      if (file.size > 10 * 1024 * 1024 && !isMP4) {
        window.alert('File size must be inferior to 10MB');
        continue;
      } else if (file.size > 25 * 1024 * 1024 && isMP4) {
        window.alert('Video file size must be inferior to 25MB');
        continue;
      }
      this.isLoading = true;
      this.pendingFiles.push(file);
    }
    input.value = '';
    this.sendPendingFiles();
  }

  public async scrollDown() {
    await this.sleep(0);
    this.perfectScrollbar?.scrollTo({ bottom: 0 });
  }

  private sortObject(unordered: any, sortArrays = false): any {
    if (!unordered || typeof unordered !== 'object') {
      return unordered;
    }

    if (Array.isArray(unordered)) {
      const newArr = unordered.map((item) => this.sortObject(item, sortArrays));
      if (sortArrays) {
        newArr.sort();
      }
      return newArr;
    }

    const ordered: any = {};
    Object.keys(unordered)
      .sort()
      .forEach((key) => {
        ordered[key] = this.sortObject(unordered[key], sortArrays);
      });
    return ordered;
  }

  public pushMessage(
    message: Message,
    disableNotif: boolean,
    doNotScroll: boolean,
    makeMessageTheLast = false,
  ): boolean {
    /*     let messageInd = this.messages.findIndex((m) => {
      return (
        // m.delivered === false &&
        JSON.stringify(this.sortObject(m.content)) === JSON.stringify(this.sortObject(message.content))
      );
    });
    if (messageInd < 0) {
      messageInd = this.messages.findIndex((m) => {
        return m.uuid === message.uuid;
      });
    } */
    const messageInd = this.messages.findIndex((m) => m.uuid === message.uuid);
    if (messageInd >= 0) {
      if (message.delivered) {
        this.messages[messageInd].delivered = true;
      }
      this.messages[messageInd].uuid = message.uuid;
      this.messages[messageInd].author.id = message.author.id;
      this.messages[messageInd].sentDate = message.sentDate;
      this.messages[messageInd].updatedDate = message.updatedDate;
      this.messages[messageInd].webchatMessageId = message.webchatMessageId;
      const contentModified = this.messages[messageInd].content !== message.content;
      this.messages[messageInd].content = message.content;

      this.messagesFilter();
      if (contentModified) void this.scrollDown();
      return false;
    }
    this.messagesPush(message, makeMessageTheLast);
    if (!doNotScroll) {
      this.scrollDown();
    } else if (this.displayService.chatStatus === 'closed') {
      this.disableScrollDownOnOpen = true;
    }
    if (this.shouldRedirect && this.redirectService) {
      this.redirectService.onMessageCall(message);
    }
    if (message.author.type === 'bot') {
      parent.window.postMessage(
        {
          type: 'bc_analytics',
          data: {
            type: 'Botmind_MessageReceivedFromBot',
          },
        },
        '*',
      );
    } else if (['agent', 'third_party'].includes(message.author.type)) {
      parent.window.postMessage(
        {
          type: 'bc_analytics',
          data: {
            type: 'Botmind_MessageReceived',
            data: { detail: message.content },
          },
        },
        '*',
      );
    }
    return true;
  }

  public removeMessage(message: Message) {
    const messageInd = this.messages.findIndex((m) => m.uuid === message.uuid);

    if (messageInd >= 0) {
      this.messagesSplice(messageInd);
    }
  }

  public sendMessageGhostMode(message?: Message) {
    this.ghostSessionBackup = utilities.jsonCopy(this.session);
    if (message) {
      this.ghostMessagesQueue.push(message);
    } else {
      this.ghostSessionSelfCareAskQuestion = true;
    }
    if (!this.modalService.isOpen('modal-collect-consent')) {
      this.enableDarkenAndBlurBackdrop = true;
      this.modalService.open('modal-collect-consent');
    }
  }

  public async consentAccepted() {
    this.ghostSessionBackup = null;
    this.modalService.close('modal-collect-consent');
    this.enableDarkenAndBlurBackdrop = false;
    await this.leaveGhostMode();
    for (const pendingMessage of this.ghostMessagesQueue) {
      const message = await this.backendService.sendMessage(pendingMessage, this.parentPageUrl, this.parentPageTitle);

      this.displayService.onNewMessage(message, this.session.dismissedPopUp);
      const messageInd = this.messages.findIndex((m) => m.uuid === message.uuid);
      if (messageInd >= 0) {
        this.messages[messageInd].delivered = true;
        this.messages[messageInd].author.id = message.author.id;
        this.messages[messageInd].updatedDate = message.updatedDate;
      }
      parent.window.postMessage(
        {
          type: 'bc_analytics',
          data: {
            type: 'Botmind_MessageSent',
            data: { detail: pendingMessage.content },
          },
        },
        '*',
      );
    }
    this.ghostMessagesQueue = [];
    if (this.ghostSessionSelfCareAskQuestion) {
      await this.backendService.selfCareAskQuestion();
      this.ghostSessionSelfCareAskQuestion = false;
    }
    if (this.session && this.session.id && typeof this.session.id === 'string') {
      this.backendService.logEvent(ChatEventType.GDPR_ACCEPT_CLICK, `gdpr_accept-${this.session.id}`);
    }
  }

  public consentDenied() {
    this.setSession(this.ghostSessionBackup);
    this.ghostSessionBackup = null;
    for (const pendingMessage of this.ghostMessagesQueue) {
      this.removeMessage(pendingMessage);
    }
    this.ghostMessagesQueue = [];
    this.ghostSessionSelfCareAskQuestion = false;
    this.modalService.close('modal-collect-consent');
    this.enableDarkenAndBlurBackdrop = false;
    if (!this.displayService.isStandalone) {
      this.displayService.closeChat(true, true);
      for (const message of this.shownMessages) {
        if (message.popNotif && !this.session.dismissedPopUp) {
          this.displayService.popTriggerService.addMessage(message);
        }
      }
    }
    this.backendService.logEvent(ChatEventType.GDPR_DECLINE_CLICK);
  }

  public async sendMessage(m?: Message): Promise<void> {
    if (this.pendingFiles.length) {
      this.sendPendingFiles();
      return;
    }
    if (!this.newMessage || this.newMessage.trim() === '') {
      this.newMessageInput.nativeElement.value = '';
      return;
    }

    const content = this.sentMessageSanitizerService.sanitize(this.newMessage);
    const now = Date.now();
    const socketMessage: Message = {
      author: this.user,
      content,
      sentDate: now,
      updatedDate: now,
      type: MessageType.TEXT,
      uuid: uuidv3(String(now), this.session.id),
      delivered: false,
      noNotif: false,
      isWelcome: false,
      isEngage: false,
      popNotif: false,
      isButtonReply: false,
      isWelcomeEngageButtonReply: false,
      isLastStep: false,
      schemaSatisfaction: null,
      showSchemaSatisfaction: false,
      webchatMessageId: null,
    };

    this.hideSatisfaction();

    this.pushMessage(socketMessage, false, false, true);
    this.setSelfCareActionButtonsDisplayedStatus(false);

    if (!this.ghostMode) {
      this.backendService.sendMessage(socketMessage, this.parentPageUrl, this.parentPageTitle).then((message) => {
        this.displayService.onNewMessage(message, this.session.dismissedPopUp);
        this.debugLog('Message got received', message);
        const messageInd = this.messages.findIndex((m) => m.uuid === message.uuid);
        if (messageInd >= 0) {
          this.messages[messageInd].delivered = true;
          this.messages[messageInd].author.id = message.author.id;
          this.messages[messageInd].sentDate = message.sentDate;
          this.messages[messageInd].updatedDate = message.updatedDate;
          return false;
        }
      });
      parent.window.postMessage(
        {
          type: 'bc_analytics',
          data: {
            type: 'Botmind_MessageSent',
            data: { detail: socketMessage.content },
          },
        },
        '*',
      );
    } else {
      this.sendMessageGhostMode(socketMessage);
    }
    this.newMessage = '';
    await this.subscribeToSessionChange(this.session.id, true);
  }

  public async sendPendingFiles() {
    // Upload each file on cloudinary
    this.uploader.addToQueue(this.pendingFiles);
    for (const fileItem of this.uploader.queue) {
      const timestamp = `${Math.floor(Date.now() / 1000)}`;
      // eslint-disable-next-line  max-len
      const folderName = `botmind-chat-${this.displayService.environment}/${this.session.accountId}/${this.session.id}`;

      const signature = await this.backendService.requestCloudinarySignature(
        timestamp,
        this.cloudinaryConfig.upload_preset,
        folderName,
      );

      fileItem.onBuildForm = (form: FormData) => {
        // Use default "withCredentials" value for CORS requests
        fileItem.withCredentials = false;
        // Add Cloudinary upload preset to the upload form
        // Add file to upload
        form.append('file', fileItem as any);
        form.append('folder', folderName);
        form.append('upload_preset', this.cloudinaryConfig.upload_preset);
        form.append('timestamp', timestamp);
        form.append('api_key', this.cloudinaryConfig.api_key);
        form.append('signature', signature);
        return form;
      };

      fileItem.onSuccess = async (response: string, status: number, headers: ParsedResponseHeaders) => {
        const data: CloudinaryResponse = JSON.parse(response);
        this.isLoading = false;
        let socketMessage: Message;
        if (fileItem.file.type.slice(0, 6) === 'image/') {
          const now = Date.now();
          socketMessage = {
            author: this.user,
            content: data.secure_url,
            sentDate: now,
            updatedDate: now,
            type: MessageType.IMAGE,
            uuid: uuidv3(String(now), this.session.id),
            delivered: false,
            noNotif: false,
            isWelcome: false,
            isEngage: false,
            popNotif: false,
            isButtonReply: false,
            isWelcomeEngageButtonReply: false,
            isLastStep: false,
            schemaSatisfaction: null,
            showSchemaSatisfaction: false,
            webchatMessageId: null,
          };
        }
        // if (fileItem.file.type.slice(0, 9) === 'video/mp4') {
        //   const now = Date.now();
        //   socketMessage = {
        //     author: this.user,
        //     content: data.secure_url,
        //     sentDate: now,
        //     updatedDate: now,
        //     type: MessageType.VIDEO,
        //     uuid: uuidv3(String(now), this.session.id),
        //     delivered: false,
        //     noNotif: false,
        //     isWelcome: false,
        //     isEngage: false,
        //     popNotif: false,
        //     isButtonReply: false,
        //     isWelcomeEngageButtonReply: false,
        //     isLastStep: false,
        //     schemaSatisfaction: null,
        //     showSchemaSatisfaction: false,
        //     webchatMessageId: null,
        //   };
        else {
          const now = Date.now();
          socketMessage = {
            author: this.user,
            content: {
              url: data.secure_url,
              contentType: fileItem.file.type.slice(0, 9) === 'video/mp4' ? 'video' : 'pdf',
            } as AttachmentMessageContent,
            sentDate: now,
            updatedDate: now,
            type: MessageType.ATTACHMENT,
            uuid: uuidv3(String(now), this.session.id),
            delivered: false,
            noNotif: false,
            isWelcome: false,
            isEngage: false,
            popNotif: false,
            isButtonReply: false,
            isWelcomeEngageButtonReply: false,
            isLastStep: false,
            schemaSatisfaction: null,
            showSchemaSatisfaction: false,
            webchatMessageId: null,
          };
        }
        this.pushMessage(socketMessage, false, false, true);

        this.setSelfCareActionButtonsDisplayedStatus(false);

        if (!this.ghostMode) {
          this.backendService.sendMessage(socketMessage, this.parentPageUrl, this.parentPageTitle).then((message) => {
            this.displayService.onNewMessage(message, this.session.dismissedPopUp);
            this.debugLog('Message got received', message);
          });
          parent.window.postMessage(
            {
              type: 'bc_analytics',
              data: {
                type: 'Botmind_MessageSent',
                data: { detail: socketMessage.content },
              },
            },
            '*',
          );
        } else {
          this.sendMessageGhostMode(socketMessage);
        }
        await this.subscribeToSessionChange(this.session.id, true);
      };
      this.uploader.uploadItem(fileItem);
    }
    this.pendingFiles.length = 0;
  }

  public displayAvatar(i: number) {
    const curr = this.shownMessages[i];
    const prev = this.shownMessages[i - 1];
    const next = this.shownMessages[i + 1];
    if (!next) {
      return true;
    }
    if (!next.author || !curr.author) {
      return false;
    }
    if (next.author.id === curr.author.id) {
      return false;
    }
    return true;
  }

  public displayName(i: number) {
    if (this.selfCareEnabled || this.chatMode === 'form') {
      return false;
    }
    const curr = this.shownMessages[i];
    const prev = this.shownMessages[i - 1];
    const next = this.shownMessages[i + 1];
    if (!curr.author) {
      return false;
    }
    if (curr.author.id === this.user.id) {
      return false;
    }
    if (!prev) {
      return true;
    }
    /* if (this.focusMessageIndex === i) {
      return true;
    } */
    if (!prev.author) {
      return false;
    }
    if (prev.author.id === curr.author.id) {
      return false;
    }
    return true;
  }

  public focusMessage(i: number) {
    this.focusMessageIndex = i;
  }

  public unFocusMessage(i: number) {
    if (this.focusMessageIndex === i) {
      this.focusMessageIndex = -1;
    }
  }

  public hideMessage(i: number) {
    const curr = this.messages[i];
    const messageContent = this.messages[i].content;
    if (
      curr.type === MessageType.STREAMED_TEXT &&
      !(curr.content as StreamedTextMessageContent).done &&
      !(curr.content as StreamedTextMessageContent).text
    ) {
      return true;
    }
    if (
      this.messages
        .slice(0, i)
        .some((m) => m.type === MessageType.STREAMED_TEXT && !(m.content as StreamedTextMessageContent).done)
    ) {
      return true;
    }
    if (Array.isArray(messageContent)) {
      if (messageContent[0].hasOwnProperty('type') && (messageContent[0] as ButtonContent).type === 'link') {
        return false;
      }
    }
    if (['buttons', 'quick_replies'].includes(curr.type)) {
      return i !== this.messages.length - 1;
    }
    return false;
  }

  public precededBySameAuthor(i: number) {
    if (i === 0) {
      return false;
    }
    if (!this.shownMessages[i - 1].author || !this.shownMessages[i].author) {
      return false;
    }
    return this.shownMessages[i - 1].author.id === this.shownMessages[i].author.id;
  }

  public followedBySameAuthor(i: number) {
    if (i === this.shownMessages.length - 1) {
      return false;
    }
    if (!this.shownMessages[i + 1].author || !this.shownMessages[i].author) {
      return false;
    }
    return this.shownMessages[i + 1].author.id === this.shownMessages[i].author.id;
  }

  public isLastMessage(i: number) {
    return this.shownMessages.length - 1 === i;
  }

  public isFirstButtonReply(i: number) {
    return this.shownMessages.length - 1 === i;
  }

  public messageFlexGrow(i: number) {
    if (i + 1 >= this.shownMessages.length) {
      return false;
    }
    return this.bottomSticky(i + 1);
  }

  public bottomSticky(i: number) {
    return this.selfCareEnabled && !this.expandHeader && this.shownMessages[i].type === MessageType.BUTTONS;
  }

  public textareaEnter(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  public trackMessageBy(index: number, el: Message): string {
    try {
      return el.uuid;
    } catch (error) {
      console.error(error);
      return `${Math.random()}`;
    }
  }

  public async updateSelfCareContactFormTextareaStatus() {
    const prevVal = this.hideTextarea;
    if (
      prevVal ===
      (this.selfCareContactFormDisableText ||
        this.selfCareContactFormWaitingReply ||
        this.selfCareContactFormWaitingEngage)
    ) {
      return;
    }
    if (this.session) {
      while (this.session.expectingMoreMessages) {
        await this.sleep(10);
      }
    }
    const newVal =
      this.selfCareContactFormDisableText ||
      this.selfCareContactFormWaitingReply ||
      this.selfCareContactFormWaitingEngage;
    if (prevVal === newVal) {
      return;
    }
    this.hideTextarea = newVal;
  }

  public getActionButtonStyle(actionButtonName: string) {
    if (actionButtonName === this.hoverActionButton) {
      const result = cloneJSON(this.displayService.chatStyle.buttonsStyle.hoveredItem);
      return result;
    }
    const result = cloneJSON(this.displayService.chatStyle.buttonsStyle.item);
    return result;
  }

  public getActionButtonContentStyle(actionButtonName: string) {
    if (actionButtonName === this.hoverActionButton) {
      const result = cloneJSON(this.displayService.chatStyle.buttonsStyle.hoveredItemContent);
      return result;
    }
    const result = cloneJSON(this.displayService.chatStyle.buttonsStyle.itemContent);
    return result;
  }

  public async setSelfCareActionButtonsDisplayedStatus(val: boolean) {
    this.session.selfCareActionButtonsDisplayed = val;
    if (!this.ghostMode) {
      const ref = doc(this.firestore, `${this.displayService.environment}-sessions-collection/${this.session.id}`);
      await updateDoc(ref, { selfCareActionButtonsDisplayed: val });
    } else {
      this.session.selfCareActionButtonsDisplayed = val;
    }
  }

  public async selfCareAskQuestion() {
    if (!this.ghostMode) {
      this.backendService.selfCareAskQuestion();
    } else {
      this.sendMessageGhostMode();
    }
  }

  public async selfCareResetAsk() {
    const tl: string = await this.translate.get('RESET_SC').toPromise();
    const tlTitle: string = await this.translate.get('RESET_SC_TITLE').toPromise();
    this.modalService.open('modal-confirm-reset');
    this.enableDarkenAndBlurBackdrop = true;
  }

  public async selfCareResetConfirm() {
    this.hideTextareaUntilButtonClick = null;

    this.modalService.close('modal-confirm-reset');
    this.enableDarkenAndBlurBackdrop = false;

    const { session: newSession } = await this.backendService.selfCareReset(
      this.parentPageUrl,
      this.parentPageTitle,
      this.ghostMode,
      this.session,
    );

    if (this.ghostMode && newSession) {
      this.setSession(newSession, false);
    }
    if (this.shouldRedirect) {
      this.redirectService.endRedirection();
    }
    this.shouldRedirect = false;
    const script = document.getElementById('zendesk-chat-web-sdk');
    if (script) {
      script.parentElement.removeChild(script);
    }
    if (this.redirectService.initialized) {
      this.redirectService.init(this.session.rhInit, this);
    }
    this.hideSatisfaction();
  }

  public selfCareResetCancel() {
    this.modalService.close('modal-confirm-reset');
    this.enableDarkenAndBlurBackdrop = false;
  }

  public voteSatisfaction(note: number) {
    this.satisfaction = note;

    if (!this.ghostMode) {
      this.backendService.setSatisfaction(this.satisfaction, '');
    } else {
      this.session.satisfaction = note;
    }
  }

  public skipSatisfaction(): void {
    this.hideSatisfaction();

    if (!this.ghostMode) {
      this.backendService.skipSatisfaction();
    } else {
      this.session.dismissedSatisfaction += 1;
    }
  }

  public validateFeedback(feedback: FeedbackPayload): void {
    if (!this.ghostMode) {
      this.backendService.setSatisfaction(this.satisfaction ? this.satisfaction : null, this.satisfactionComment);
    } else {
      this.session.satisfaction = this.satisfaction ? this.satisfaction : null;
      this.satisfactionComment = feedback.comment;
    }
  }

  public hideSatisfaction() {
    this.promptingSatisfaction = false;
    this.enableDarkenAndBlurBackdrop = false;
    this.satisfaction = null;
    this.satisfactionComment = '';

    if (!this.ghostMode) {
      const ref = doc(
        this.firestore,
        `${this.displayService.environment}-sessions-collection/${this.backendService.sessionId}`,
      );
      updateDoc(ref, { promptSatisfaction: false });
    } else {
      this.session.promptSatisfaction = false;
    }
  }

  public getFullName(user: User) {
    return User.getFullName(user);
  }

  public toggleChatStatus(s: boolean) {
    setTimeout(() => {
      this.displayService.toggleChatStatus(s, this);
    }, widgetAnimationDurationSeconds * 1000);
  }

  private getHistory() {
    const types = {
      lead: '🧑',
      visitor: '🧑',
      agent: '👨‍💼',
      system: '🤖',
      bot: '🤖',
      third_party: '👨‍💼',
    };
    const history = 'HISTORY:\n'.concat(
      this.messages
        .map((message) => {
          let content: string;
          let temp: any;
          switch (message.type) {
            case MessageType.TEXT:
            case MessageType.IMAGE:
              content = message.content as string;
              break;
            case MessageType.TEXT_PAYLOAD:
              temp = message.content as TextPayloadMessageContent;
              content = `${temp.text}(${temp.payload})`;
              break;
            case MessageType.BUTTONS:
              content = (message.content as ButtonContent[])
                .map((c) => {
                  return `[${c.text}](${c.type}:${c.content})`;
                })
                .join('|');
              break;
            case MessageType.QUICK_REPLIES:
              content = (message.content as string[]).join('|');
              break;
            default:
              content = '';
              break;
          }
          return `[${types[message.author.type]} ${User.getFullName(message.author) || ''}] ${content}`;
        })
        .join('\n\n'),
    );
    return history;
  }

  public async leaveGhostMode() {
    const { sessionId } = await this.backendService.leaveGhostMode(this.session.id, this.session, this.messages);
    this.ghostMode = false;
    if (this.session.id !== sessionId) {
      this.session.id = sessionId;
      this.messagesAssign(
        utilities.jsonCopy(
          this.messages.map((m) => {
            return { ...m, uuid: uuidv3(String(m.sentDate), this.session.id) };
          }),
        ),
      );
      for (const m of this.ghostMessagesQueue) {
        m.uuid = uuidv3(String(m.sentDate), this.session.id);
      }
      if (this.session.triggerData && this.session.triggerData.messages)
        for (const m of this.session.triggerData.messages) {
          m.uuid = uuidv3(String(m.sentDate), this.session.id);
        }
      if (this.session.welcomeTriggerData && this.session.welcomeTriggerData.messages)
        for (const m of this.session.welcomeTriggerData.messages) {
          m.uuid = uuidv3(String(m.sentDate), this.session.id);
        }
    }
    await this.subscribeToSessionChange(this.session.id);
    if (this.session.rhInit) {
      this.redirectService.init(this.session.rhInit, this);
    }
    parent.window.postMessage({ type: 'bc_leavingGhostMode', data: sessionId }, '*');
  }

  public focusTextarea(event: MouseEvent): void {
    event.stopPropagation();
    this.newMessageInput.nativeElement.focus();
  }

  public getIndexOfLastMessageByUserType(author: 'visitor' | 'bot'): number {
    return this._findLastIndex(this.shownMessages, (m) => {
      if (author === 'visitor') {
        return m.author.type === ActorType.USER || m.author.type === ActorType.LEAD;
      } else if (author === 'bot') {
        return m.author.type === ActorType.BOT || m.author.type === ActorType.SYSTEM;
      }
    });
  }

  private getLastChunkOfMessagesByUserType(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    messages: Message[] = this.shownMessages,
    author: 'visitor' | 'bot',
  ): Message[] {
    const recentToOld: Message[] = messages.reverse();
    const chunkOfMessages: Message[] = [];

    if (recentToOld?.length > 0) {
      let authorTypesToKeep: ActorType[] = [];

      if (author === 'bot') {
        authorTypesToKeep = [ActorType.BOT, ActorType.SYSTEM];
      } else if (author === 'visitor') {
        authorTypesToKeep = [ActorType.USER, ActorType.LEAD];
      }

      for (let i = 0; i < recentToOld.length; i++) {
        const message: Message = recentToOld[i];

        if (!authorTypesToKeep.includes(message.author.type)) {
          break;
        } else {
          chunkOfMessages.push(message);
        }
      }
    }

    const newToOld: Message[] = chunkOfMessages.sort((a, b) => {
      return a.sentDate - b.sentDate;
    });

    return newToOld;
  }

  public async setSchemaSatisfaction(payload: SchemaSatisfactionPayload, msgIdx: number): Promise<void> {
    const result = await this.backendService.setSchemaSatisfaction(payload.schemaSatisfaction, payload.messageId);

    if (!result.success) {
      this.shownMessages[msgIdx].schemaSatisfaction = payload.previousSchemaSatisfaction;
    }
  }

  public getButtonsStyle() {
    return this.displayService.chatStyle.buttonsStyle.buttons;
  }

  public getButtonsItemStyle(i: number) {
    if (i === this.hoverInd) {
      return this.displayService.chatStyle.buttonsStyle.hoveredItem;
    }
    return this.displayService.chatStyle.buttonsStyle.item;
  }

  public autoOpenWebview(): void {
    if (this.shownMessages?.length > 0) {
      const webviewMessage = this.shownMessages.filter((m) => m.type === MessageType.WEBVIEW)[
        this.shownMessages.filter((m) => m.type === MessageType.WEBVIEW).length - 1
      ];

      if (webviewMessage) {
        const webviewMessageIsLastMessage =
          this.shownMessages.findIndex((m) => m?.uuid === webviewMessage?.uuid) === this.shownMessages.length - 1;

        if (webviewMessageIsLastMessage) {
          this.showWebview(webviewMessage.content as WebviewMessageContent);
        }
      }
    }
  }

  private addUrlParamsToCustomData(pageUrl: string, rawCustomData: string): { key: string; value: string }[] {
    const parsedRawCustomData: { key: string; value: string }[] = JSON.parse(rawCustomData);

    try {
      const pageUrlParams: { key: string; value: string }[] = [];

      // Turn all URL parameters into objects to push them in the `pageUrlParams` array
      new URL(pageUrl).searchParams.forEach((v: string, k: string) => {
        if (k && k !== '' && v && v !== '') {
          const urlParamKeyValue: { key: string; value: string } = { key: '', value: '' };

          // Making sure that `k` and `v` are strings
          urlParamKeyValue.key = `${k}`;
          urlParamKeyValue.value = `${v}`;

          pageUrlParams.push(urlParamKeyValue);
        }
      });

      if (pageUrlParams?.length > 0) {
        for (const urlParam of pageUrlParams) {
          /**
           * WARN:
           * You should be aware that if there is one object coming from
           * the `pageUrlParams` array in which the `key` key has a value
           * which already exists in one of the objects in the `rawCustomData` array,
           * only one of those two similar objects will be kept,
           * and that will be the object coming from `rawCustomData`.
           */
          const existingKeysInRawCustomData: string[] = parsedRawCustomData.map((kv) => kv.key.trim().toLowerCase());

          if (!existingKeysInRawCustomData.includes(urlParam.key.trim().toLowerCase())) {
            parsedRawCustomData.push(urlParam);
          }
        }
      }
    } catch (error) {
      console.warn('Invalid URL parameters, skipping URL params addition to custom data');
    }

    return parsedRawCustomData;
  }

  public isVisitor(message: Message): boolean {
    return (
      !message.author ||
      message.author.id === 'act_00000000-0000-0000-0000-000000000000' ||
      (this.user && this.user.id === message.author.id)
    );
  }

  // Utils
  private _findLastIndex<T>(
    list: Array<T>,
    predicate: (value: T, index: number, obj: T[]) => unknown,
  ): number | undefined {
    for (let index = list.length - 1; index >= 0; index--) {
      const currentValue = list[index];
      const predicateResult = predicate(currentValue, index, list);

      if (predicateResult) {
        return index;
      }
    }
    return undefined;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub?.unsubscribe?.());
  }
}
